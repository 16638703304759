import clsx from 'clsx'
import React from 'react'
import { Button } from '../button/Button.component';
import { PaginationInfo } from '../../../hooks';
import { Select } from '../select/Select.component';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';


export interface PaginationProps {
  pagination: PaginationInfo;
  alwaysShow?: boolean;
  change?: (value: 'next' | 'previous' | number) => void;
  itemCountChange?: (value: number) => void;
  enableNext?: boolean;
  enablePrevious?: boolean;
  enableFirst?: boolean;
  enableLast?: boolean;
  enableCount?: boolean;
  disablePageNumberButton?: boolean;
}

export type PaginationType = React.ComponentPropsWithRef<'nav'> & PaginationProps;

export const Pagination: React.FunctionComponent<PaginationType> = ({
  pagination,
  change,
  alwaysShow = false,
  enableNext,
  enablePrevious,
  enableFirst,
  enableLast,
  enableCount = true,
  itemCountChange,
  disablePageNumberButton = false,
  ...props
}: PaginationType): JSX.Element => {

  const getPageList = () => {
    
    const pagesTotal = Math.ceil((pagination.totals.current || 1) / pagination.itemsPerPage.current);

    if (pagesTotal >= 6 && pagination.pages.current > 3) {
      const pagesUntilEnd = pagesTotal - pagination.pages.current;
      if (pagesUntilEnd <= 1) {
        if (pagesUntilEnd === 1) {
            return [...Array(5).keys()].map(i => i + 1 + (pagination.pages.current - 4));
        } else {
            return [...Array(5).keys()].map(i => i + 1 + (pagination.pages.current - 5));
        }
      }
      return [...Array(5).keys()].map(i => i + 1 + (pagination.pages.current - 3));
    }
    return [...Array(Math.min(5, pagesTotal)).keys()].map(i => i + 1);
  }

  return (
    <>
    {
      (pagination.totals.current > pagination.itemsPerPage.current) || alwaysShow ? 
      <PaginationWrapper className="mt-4 flex flex-row gap-x-4 w-full" {...props}>

        <div className={clsx(
          'grid w-full',
          enableCount ? 
          'grid-cols-[20px_20px_1fr_20px_20px_80px] sm:grid-cols-[100px_100px_1fr_100px_100px_120px]' :
          'grid-cols-[30px_1fr_30px_80px] sm:grid-cols-[100px_1fr_100px_120px]'
        )}>
          {enableCount ?
            <PaginationFirst 
              action={change ? () => change(0) : pagination.pages.change}
              currentPage={pagination.pages.current} 
              enableFirst={enableFirst} 
            /> : null
          }

          <PaginationPrevious 
            action={change ? () => change('previous') : pagination.pages.change} 
            currentPage={pagination.pages.current}
            enablePrevious={enablePrevious}
          />

          <PaginationList>
            {
              getPageList().map((page: number) => {
                return <PaginationPage 
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  action={ disablePageNumberButton ? () => {} : (change ? () => change(page) : pagination.pages.change)} 
                  pageNumber={page} 
                  current={page === pagination.pages.current + 1}
                  key={page}
                >
                  { page.toString() }
                </PaginationPage>
              })
            }
          </PaginationList>

          <PaginationNext 
            pageTotal={Math.ceil(pagination.totals.current / pagination.itemsPerPage.current)} 
            action={change ? () => change('next') : pagination.pages.change} 
            currentPage={pagination.pages.current} 
            enableNext={enableNext}
          />

          {enableCount ?
            <PaginationLast
              pageTotal={Math.ceil(pagination.totals.current / pagination.itemsPerPage.current)} 
              action={change ? () => change(Math.ceil(pagination.totals.current / pagination.itemsPerPage.current)) : pagination.pages.change}
              currentPage={pagination.pages.current} 
              enableLast={enableLast} 
            /> : null
          }

          <PaginationContext
            currentPage={pagination.pages.current}
            itemsPerPage={pagination.itemsPerPage.current}
            totalItems={pagination.totals.current}
            pageTotal={Math.ceil(pagination.totals.current / pagination.itemsPerPage.current)}
          />
        </div>

        <PaginationRowsPerPage 
          pagination={pagination}
          itemCountChange={itemCountChange}
        />

      </PaginationWrapper> : null
    }
    </>
  )
}

export function PaginationRowsPerPage({
  'aria-label': ariaLabel = 'Rows Per Page',
  pagination,
  className,
  itemCountChange,
  ...props
}: { pagination: PaginationInfo; itemCountChange?: (value: number) => void; } & React.ComponentPropsWithoutRef<'div'>) {
  return <div aria-label={ariaLabel} {...props} className={clsx(className, 'w-24 relative')}>
    <Select
      onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
        if (itemCountChange) {
          itemCountChange(parseInt(value.target.value));
        }
        pagination.itemsPerPage.change(parseInt(value.target.value))
      }}
      value={pagination.itemsPerPage.current}
    >
      {
        pagination.itemsPerPage.options.map((value: number) => {
          return <option
            value={value}
            key={value}
          >
            { value }
          </option>
        })
      }
    </Select>
  </div>
}

export function PaginationWrapper({
  'aria-label': ariaLabel = 'Page navigation',
  className,
  ...props
}: React.ComponentPropsWithoutRef<'nav'>) {
  return <nav aria-label={ariaLabel} {...props} className={clsx(className, 'flex gap-x-2')} />
}

export function PaginationFirst({
    children = 'First',
    action,
    currentPage,
    enableFirst = null
  }: {
    children?: React.ReactNode,
    action: (page: number) => void,
    currentPage: number,
    enableFirst?: boolean | null
  }) {
  return (
    <span className='basis-0'>
      <Button
        className='pl-1 pr-1'
        disabled={ (enableFirst !== null && !enableFirst) || currentPage === 0} 
        onClick={() => action(0)} 
        plain 
        aria-label="First page"
      >
        <ChevronDoubleLeftIcon />
        <span className='hidden sm:block'>{ children }</span>
      </Button>
    </span>
  )
}

export function PaginationLast({
    children = 'Last',
    action,
    currentPage,
    pageTotal,
    enableLast = null
  }: {
    children?: React.ReactNode,
    action: (page: number) => void,
    currentPage: number,
    pageTotal: number,
    enableLast?: boolean | null
  }) {
  return (
    <span className='flex basis-0 justify-end'>
      <Button
        className='pl-1 pr-1' 
        disabled={ (enableLast !== null && !enableLast) || currentPage === pageTotal - 1} 
        onClick={() => action(pageTotal - 1) } 
        plain 
        aria-label="Last page"
      >
        <span className='hidden sm:block'>{ children }</span>
        <ChevronDoubleRightIcon />
      </Button>
    </span>
  )
}

export function PaginationPrevious({
  children = 'Previous',
  action,
  currentPage,
  enablePrevious = null
}: {
  children?: React.ReactNode,
  action: (page: number) => void,
  currentPage: number,
  enablePrevious?: boolean | null
}) {
  return (
    <span className="grow basis-0">
      <Button 
        className='pl-1 pr-1'
        disabled={ (enablePrevious !== null && !enablePrevious) || currentPage === 0} 
        onClick={() => action(currentPage === 0 ? 0 : currentPage - 1)} 
        plain 
        aria-label="Previous page"
      >
        <ChevronLeftIcon />
        <span className='hidden sm:block'>{children}</span>
      </Button>
    </span>
  )
}

export function PaginationNext({
  children = 'Next',
  action,
  currentPage,
  pageTotal,
  enableNext = null
}: {
  children?: React.ReactNode,
  action: (page: number) => void,
  currentPage: number,
  pageTotal: number,
  enableNext?: boolean | null
}) {
  return (
    <span className="flex basis-0 justify-end">
      <Button
        className='pl-1 pr-1'
        onClick={ () => action(currentPage === pageTotal ? currentPage : currentPage + 1) }
        disabled={ (enableNext !== null && !enableNext) || currentPage === pageTotal - 1 }
        plain 
        aria-label="Next page"
      >
        <span className='hidden sm:block'>{children}</span>
        <ChevronRightIcon />
      </Button>
    </span>
  )
}

export function PaginationList({ children }: { children: React.ReactNode }) {
  return <span className="mx-auto grow justify-center items-baseline gap-x-2 sm:flex whitespace-nowrap">{children}</span>
}

export function PaginationContext({ currentPage, itemsPerPage, totalItems, pageTotal }: { currentPage: number, itemsPerPage: number, totalItems: number, pageTotal: number}) {
  const firstItem = currentPage * itemsPerPage + 1;
  let lastItem;
  if (currentPage === pageTotal - 1) {
    lastItem = totalItems;
  } else {
    lastItem = (currentPage + 1) * itemsPerPage;
  }
  return <span className="justify-end flex sm:justify-center sm:ml-3 my-auto text-sm">{firstItem}-{lastItem} of {totalItems}</span>
}

export function PaginationPage({
  children,
  current = false,
  action,
  pageNumber
}: {
  children: string
  current?: boolean
  action: (page: number) => void,
  pageNumber: number
}) {
  return (
    <Button
      onClick={() => action(pageNumber - 1)}
      plain
      aria-label={`Page ${children}`}
      aria-current={current ? 'page' : undefined}
      className={clsx(
        current ? 'before:bg-zinc-950/5 dark:before:bg-white/10 min-w-9 before:absolute before:-inset-px before:rounded-lg' :
        'hidden sm:block min-w-9 before:absolute before:-inset-px before:rounded-lg',
      )}
    >
      <span className="-mx-0.5">{children}</span>
    </Button>
  )
}

export function PaginationGap() {
  return (
    <div
      aria-hidden="true"
      className="w-9 select-none text-center text-sm/6 font-semibold text-zinc-950 dark:text-white"
    >
      &hellip;
    </div>
  )
}
