import Axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar } from "../../hooks";
import { useLogin } from "../login";
import { PaginatedResponseType } from "../../globals/types";
import {
    IMockup,
    IGetMockupsRequest,
    mockupsAtom,
    mockupsLoadingAtom,
    hasMoreMockupsAtom,
} from "./types";
import axios from "axios";
import { buildQueryStringFromParams } from "../../components/helpers";

type SetMockupType = (payload: PaginatedResponseType<IMockup>) => void;
type FetchMockupsType = (query: IGetMockupsRequest, lazy?: boolean) => void;
type DeleteMockupType = (mockup_id: number) => Promise<boolean>;
type AddMockupType = (form: FormData) => Promise<IMockup | null>;
type UpdateMockupType = (id: number, form: FormData) => Promise<IMockup | null>;

type MockupKeyFunctions = {
    get: FetchMockupsType;
    delete: DeleteMockupType;
    add: AddMockupType;
    update: UpdateMockupType;
}

type UseMockups = [
    PaginatedResponseType<IMockup>,
    SetMockupType,
    MockupKeyFunctions
];

export const useMockups = (): UseMockups => {
    const [mockups, setMockups] = useAtom(mockupsAtom);
    const setHasMoreMockups = useSetAtom(hasMoreMockupsAtom);

    const [,setSnackbar] = useSnackbar();
    const [,authToken, loginFunctions] = useLogin();
    const setMockupsLoading = useSetAtom(mockupsLoadingAtom);

    const getMockups = async (query: IGetMockupsRequest, lazy = false) => {
        setMockupsLoading(true);
        return await axios.get(`${environment.mockupsUrl}${buildQueryStringFromParams(query)}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        })
            .then((mockupsResults: AxiosResponse<PaginatedResponseType<IMockup>>) => {
                if (mockupsResults?.data) {



                    const mockupsData = lazy ? {
                        ...mockupsResults.data,
                        results: [
                            ...mockups.results,
                            ...mockupsResults.data.results.filter((mockup: IMockup) => {
                                const foundExisting = mockups.results.find((existingMockup: IMockup) => existingMockup.id === mockup.id);
                                if (foundExisting) {
                                    return false
                                }
                                return true;
                            })
                        ]
                    } : mockupsResults.data;
                    if (!mockupsData.hasNext) {
                        setHasMoreMockups(false);
                    }
                    setMockups(mockupsData);
                    return mockupsResults.data;
                } else {
                    setSnackbar({
                        show: true,
                        snackbarLevel: 'error',
                        text: 'There was an error while getting mockups. Please try again later.'
                    })
                }
            })
            .catch((err: any) => {
                errorCatcher(err, loginFunctions.logout)
            })
            .finally(() => setMockupsLoading(false));
    }

    const add = async (form: FormData): Promise<IMockup | null> => {
        const insertResponse: AxiosResponse<IMockup> | void = await Axios.post(environment.mockupsUrl, form, {
            headers: {
                'X-Wallmates-Auth': authToken,
                "Content-Type": "multipart/form-data"
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));

        if (insertResponse) {
            mockups.results = [...mockups.results, insertResponse.data];
            setMockups(mockups);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Mockup added successfully.'
            })
            return insertResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while adding a new mockup. Please try again later.'
            })
            return null;
        }
    }

    const deleteMock = async (mockupId: number): Promise<boolean> => {
        const deleteResponse: AxiosResponse<boolean> | void = await Axios.delete(`${environment.mockupsUrl}?mockup_id=${mockupId}`, {
            headers: {
                'X-Wallmates-Auth': authToken
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (deleteResponse) {
            const resetMockups = mockups;
            resetMockups.results = mockups.results.filter((item: IMockup) => item.id !== mockupId);
            setMockups(resetMockups);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Mockup deleted successfully.'
            })
            return deleteResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while deleting a mockup. Please try again later.'
            })
            return false;
        }
    }

    const updateMockup = async (id: number, body: FormData): Promise<IMockup | null> => {
        const updateResponse: AxiosResponse<IMockup> | void = await Axios.put(`${environment.mockupsUrl}`, body, {
            headers: {
                'X-Wallmates-Auth': authToken,
                "Content-Type": "multipart/form-data"
            }
        }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
        if (updateResponse) {
            const resetMockups = mockups;
            resetMockups.results = [...mockups.results.map((mockup: IMockup) => {
                if (mockup.id === id) {
                    return updateResponse.data;
                }
                return mockup
            })];
            setMockups(resetMockups);
            setSnackbar({
                show: true,
                snackbarLevel: 'info',
                text: 'Mockup updated successfully.'
            })
            return updateResponse.data;
        } else {
            setSnackbar({
                show: true,
                snackbarLevel: 'error',
                text: 'There was an error while updating a mockup. Please try again later.'
            })
            return null;
        }
    }

    const keyFunctions: MockupKeyFunctions = {
        add: add,
        delete: deleteMock,
        get: getMockups,
        update: updateMockup
    }

    return [
        mockups,
        setMockups,
        keyFunctions
    ]
}

