import React, { PropsWithChildren } from "react";
import Loader from "../../loader/Loader.component";


export interface SpinnerProps {
    show: boolean;
    size?: number;
    reverse?: boolean;
}

type SpinnerType = PropsWithChildren<SpinnerProps>;

export const Spinner: React.FunctionComponent<SpinnerType> = (props: SpinnerType): JSX.Element => {
    const { show, size = 50, reverse = false } = props;

    return (
        <>
            {
                show ? <div role="status" className="m-0 p-0">
                    <Loader size={size} reverse={reverse} />
                    <span className="sr-only">Loading...</span>
                </div> : null
            }
        </>
    );
}