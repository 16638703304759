import axios, { AxiosResponse } from "axios";
import { useAtom, useSetAtom } from "jotai";
import { environment } from "../../environment";
import { errorCatcher, useSnackbar, useSocket } from "../../hooks";
import { useLogin } from "../login";
import {
    IScanRequest,
    IScanResponse,
    IManualSearch,
    currentScanItemAtom,
    scanLoadingAtom,
    lastScanValueAtom
} from "./types";

type ScanItem = (query: IScanRequest) => Promise<IScanResponse | null>;
type CreateBase64 = (value: string) => string;
type PrintZPL = (zpl: string, print_node_id: number, station_name: string) => void;
type ManualSearchType = (body: IManualSearch) => Promise<IScanResponse | null>;

type UseScannerKeyFunctions = {
    scan: ScanItem,
    base64: CreateBase64,
    printZPL: PrintZPL,
    manualSearch: ManualSearchType
}

type UseScanner = [
    IScanResponse | null,
    UseScannerKeyFunctions
];

export const useScanner = (): UseScanner => {

    const [scan, setScan] = useAtom(currentScanItemAtom);
    const setScanLoading = useSetAtom(scanLoadingAtom);
    const setLastScanValue = useSetAtom(lastScanValueAtom);
    const [employee, authToken, loginFunctions] = useLogin();
    const [, setSnackbar] = useSnackbar();
    const [, socketFunctions] = useSocket();

    const scanItem = async (query: IScanRequest): Promise<IScanResponse | null> => {
        setScanLoading(true);
        setLastScanValue(query.scanValue);
        if (employee !== null) {
            const scanResponse: AxiosResponse<IScanResponse> | void = await axios.post(`${environment.scanUrl}/scan-item`, { ...query, employeeId: employee.id }, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (scanResponse) {
                const consumables = [
                    '#box-1#',
                    '#box-2#',
                    '#squeegee#',
                    '#luxpaste#'
                ]
                if (consumables.includes(scanResponse.data.scanValue)) {
                    socketFunctions.sendMessage(JSON.stringify({
                        type: 'scanner'
                    }));
                }

                if (scanResponse.data && (scanResponse.data as any).message && (scanResponse.data as any).message === 'Waste scanned') {
                    socketFunctions.sendMessage(JSON.stringify({
                        type: 'scanner'
                    }));
                    return null;
                } else {
                    setScan(scanResponse.data);
                    setScanLoading(false);
                    return scanResponse.data;
                }
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while performing scan'
                })
                setScan(null);
                setScanLoading(false);
                return null;
            }
        } else {
            loginFunctions.logout();
            return null;
        }
    }

    const manualSearch = async (query: IManualSearch): Promise<IScanResponse | null> => {
        setScanLoading(true);
        setLastScanValue(query.query);
        if (employee !== null) {
            const scanResponse: AxiosResponse<IScanResponse> | void = await axios.post(`${environment.scanUrl}/manual-search`, query, {
                headers: {
                    'X-Wallmates-Auth': authToken
                }
            }).catch((err: any) => errorCatcher(err, loginFunctions.logout));
            if (scanResponse) {
                setScan(scanResponse.data);
                setScanLoading(false);
                return scanResponse.data;
            } else {
                setSnackbar({
                    show: true,
                    snackbarLevel: 'error',
                    text: 'There was an error while performing scan'
                })
                setScan(null);
                setScanLoading(false);
                return null;
            }
        } else {
            loginFunctions.logout();
            return null;
        }
    }

    const toBase64 = (value: string) => {
        return btoa(unescape(encodeURIComponent(value)));
    }

    const printZPL = async (zplString: string, printerId: number, selectedUser: string) => {
        const printNodeAPIKey = "1hcOF0731gdfA9wZIkOLYIRqCBSJm5wW_7fQDQw_Pb0";
        const idempotencyKey = generateIdempotencyKey();
        const source = "FROM SCAN";

        try {
            await axios({
                url: environment.printNodeUrl,
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "X-Idempotency-Key": idempotencyKey,
                    "Authorization": "Basic " + btoa(printNodeAPIKey + ":"),
                },
                data: JSON.stringify({
                    printerId,
                    title: "Packing Slip",
                    contentType: "raw_base64",
                    content: zplString,
                    source,
                    user: selectedUser,
                    expireAfter: 600,
                    options: { copies: 1, pages: 1 }
                })
            });
        } catch (e: any) {
            if (e.response?.data?.code !== 'Conflict') {
                console.error(e);
                throw new Error('printing error');
            }
        }
    }

    const generateIdempotencyKey = () => {
        return "key-" + Date.now();
    }

    const scanKeyFunctions: UseScannerKeyFunctions = {
        scan: scanItem,
        base64: toBase64,
        printZPL,
        manualSearch
    }

    return [
        scan,
        scanKeyFunctions
    ]

}
