import React, { Suspense, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Loader from '../loader/Loader.component';
import {
    HomeIcon,
    XMarkIcon,
    PhotoIcon,
    LanguageIcon,
    KeyIcon,
    GiftTopIcon,
    IdentificationIcon,
    BuildingStorefrontIcon,
    RectangleStackIcon,
    CodeBracketSquareIcon,
    QrCodeIcon,
    BoltIcon,
    EyeDropperIcon,
    UserGroupIcon,
    UserIcon,
    ScaleIcon,
    ComputerDesktopIcon,
    ListBulletIcon,
    PaperAirplaneIcon,
    BanknotesIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import { NavigationItem } from './Dashboard.types';
import { Navigator } from './navigation/Navigation.component';
import { StickyBar } from './navigation/Sticky-Bar.component';
import { NotificationsWrapper } from './navigation/Notifications.component';
import { RouteConstants } from '../../Routes';
import { ITranslationLanguage, translationsAtom, useTranslationLanguageKeys, useTranslationLanguages } from '../../services';
import { useAtom } from 'jotai';
import { useSnackbar } from '../../hooks';
import { Button, DialogActions, DialogBody, DialogTitle, Field, Label, Select, Slideover, Dialog as DashboardDialog, Snackbar } from '../UI';
import { Dialog, Transition } from '@headlessui/react';

const navigationDefault: NavigationItem[] = [
  { name: 'NAVIGATION.DASHBOARD', href: RouteConstants.HOME, icon: HomeIcon, current: true, expanded: false, permissionLevel: 'EVERYBODY' },
  { name: 'NAVIGATION.PRODUCTS', href: RouteConstants.PRODUCT_HOME, icon: PhotoIcon, current: false, expanded: false, children: [], permissionLevel: 'USER' },
  { name: 'NAVIGATION.ORDERS', href: RouteConstants.ORDERS, icon: BuildingStorefrontIcon, current: false, expanded: false, children: [], permissionLevel: 'EVERYBODY' },
  { name: 'NAVIGATION.CUSTOMERS', href: RouteConstants.CUSTOMERS, icon: UserIcon, current: false, children: [], expanded: false, permissionLevel: 'EVERYBODY' },
  { name: 'NAVIGATION.COLLECTION', href: RouteConstants.COLLECTION, icon: RectangleStackIcon, current: false, expanded: false, children: [], permissionLevel: 'USER' },
  { name: 'NAVIGATION.INVOICES', href: RouteConstants.INVOICES, icon: BanknotesIcon, current: false, expanded: false, permissionLevel: 'ADMIN' },
  { name: 'NAVIGATION.USERS', href: RouteConstants.USERS, icon: UserGroupIcon, current: false, children: [], expanded: false, permissionLevel: 'ADMIN' },
  { name: 'NAVIGATION.MOCKUP', href: RouteConstants.MOCKUP, icon: GiftTopIcon, current: false, expanded: false, children: [], permissionLevel: 'SUPERUSER' },
  { name: 'NAVIGATION.PRODUCTION', href: RouteConstants.SCANNER, icon: BoltIcon, current: false, expanded: false, permissionLevel: 'TECHNICIAN', children: [
      { name: 'NAVIGATION.SCANNER', href: RouteConstants.SCANNER, icon: QrCodeIcon, current: false, permissionLevel: 'TECHNICIAN' },
      { name: 'NAVIGATION.WEIGH_STATION', href: RouteConstants.WEIGH_STATION, icon: ScaleIcon, current: false, permissionLevel: 'TECHNICIAN' },
      { name: 'NAVIGATION.SHIP_STATION', href: RouteConstants.SHIP_STATION, icon: PaperAirplaneIcon, current: false, permissionLevel: 'TECHNICIAN' },
      { name: 'NAVIGATION.WASTE_TRACKING', href: RouteConstants.WASTE_TRACKING, icon: TrashIcon, current: false, permissionLevel: 'TECHNICIAN' },
      { name: 'NAVIGATION.JDML', href: RouteConstants.JDML, icon: CodeBracketSquareIcon, current: false, permissionLevel: 'TECHNICIAN' },
      { name: 'NAVIGATION.DROPPER', href: RouteConstants.DROPPER, icon: EyeDropperIcon, current: false, permissionLevel: 'TECHNICIAN' }
  ]},
  { name: 'NAVIGATION.DEVELOPMENT', href: RouteConstants.TRANSLATIONS, icon: ComputerDesktopIcon, current: false, expanded: false, permissionLevel: 'SUPERADMIN', children: [
      { name: 'NAVIGATION.TRANSLATIONS', href: RouteConstants.TRANSLATIONS, icon: LanguageIcon, current: false, permissionLevel: 'SUPERADMIN' },
      { name: 'NAVIGATION.TRANSLATION_KEYS', href: RouteConstants.TRANSLATION_KEYS, icon: KeyIcon, current: false, permissionLevel: 'SUPERADMIN' },
      { name: 'NAVIGATION.MANAGE_CHECKLIST', href: RouteConstants.MANAGE_CHECKLIST, icon: ListBulletIcon, current: false, permissionLevel: 'SUPERADMIN' },
      { name: 'NAVIGATION.BRANDS', href: RouteConstants.BRANDS, icon: IdentificationIcon, current: false, permissionLevel: 'SUPERADMIN' }
  ]},
]

const Dashboard: React.FunctionComponent<{}> = (): React.ReactElement => {

    const [,,TranslationKeyFunctions] = useTranslationLanguageKeys();
    const [languages,,LanguageKeyFunctions] = useTranslationLanguages();

    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [navigation, setNavigation] = React.useState(navigationDefault);
    const [showLanguageDialog, setShowLanguageDialog] = React.useState(false);
    const [notificationSlideoverOpen, setNotificationSlideoverOpen] = React.useState(false);
    const [selectedLanguage, setSelectedLanguage] = React.useState('EN');
    const [,setTranslations] = useAtom(translationsAtom);

    const [snackbar, setSnackbar] = useSnackbar();

    const imagesBucket = process.env.REACT_APP_AWS_BUCKET_IMAGES || '';

    React.useEffect(() => {
        if (languages.length === 0) {
            LanguageKeyFunctions.get();
        }

        const language = parseInt(localStorage.getItem('language') || '1');
        TranslationKeyFunctions.getTranslationsByLanguageId(language).then((translations: {[key: string]: string} | null) => {
            if (translations) {
                setTranslations(translations);
            }
        });

        /** -- Gorgias Chat Widget Start == */
        if (!document.getElementById('gorgias-chat-container')) {
            const script = document.createElement('script');
            script.id = 'gorgias-chat-widget-install-v3';
            script.src = 'https://config.gorgias.chat/bundle-loader/01JJ2XJQ24DFEB96SXJ2YVXQ21';
            script.async = true;
            document.body.appendChild(script);
            setTimeout(() => {
                const chat = document.getElementById('gorgias-chat-container');
                if (chat) {
                    chat.style.position = 'relative';
                    chat.style.zIndex = '0';
                }
            }, 1000);

            return () => {
                document.body.removeChild(script);
            };
        }
        /** -- Gorgias Chat Widget End == */
    }, []);

    const handleLanguageSelect = async () => {
        const lang = (languages.find((item: ITranslationLanguage) => item.language_key === selectedLanguage) as ITranslationLanguage).id;
        localStorage.setItem('language', (lang as any).toString());
        const translations = await TranslationKeyFunctions.getTranslationsByLanguageId(lang as number);
        if (translations) {
            setTranslations(translations);
        }
        setShowLanguageDialog(false);
    }

    return (
        <div className="w-screen h-screen overflow-x-hidden relative">
            <Snackbar 
                colorType={snackbar.snackbarLevel} 
                show={snackbar.show} 
                showUpdate={(value: boolean) => {
                    setSnackbar({ text: snackbar.text, show: value, snackbarLevel: snackbar.snackbarLevel, duration: 5 });
                }}
                text={snackbar.text}
                dissapearIn={snackbar.duration ? snackbar.duration : 5}
            />

            {/* mobile sticky header */}
            <div className="absolute top-0 left-0 w-full h-14 lg:hidden">
                <StickyBar setSidebarOpen={setSidebarOpen} />
            </div>
            
            {/* Mobile navigation slide Animation */}
            <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 dark:bg-stone-900/65 bg-stone-300/65" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-50">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                <span className="sr-only">Close sidebar</span>
                                <XMarkIcon className="h-6 w-6 dark:text-white text-black" aria-hidden="true" />
                            </button>
                            </div>
                        </Transition.Child>

                        <Navigator 
                            navigation={navigation} 
                            setNavigation={(results: NavigationItem[]) => {
                                setNavigation(results);
                            }} 
                            closeSidebar={setSidebarOpen} 
                            setNotificationSlideoverOpen={setNotificationSlideoverOpen}
                            setShowLanguageDialog={setShowLanguageDialog}
                        />

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
            </Transition.Root>

            {/* desktop static navigation */}
            <div className="hidden lg:block">
                <Navigator  
                    navigation={navigation} 
                    setNavigation={(results: NavigationItem[]) => {
                        setNavigation(results);
                    }} closeSidebar={setSidebarOpen}
                    setNotificationSlideoverOpen={setNotificationSlideoverOpen}
                    setShowLanguageDialog={setShowLanguageDialog}
                />
            </div>

            {/* Main Content Container */}
            <div className="h-screen w-full lg:w-[calc(100%+17px)] overflow-hidden">
                <main className="h-full lg:pl-72 overflow-y-auto pb-20">
                    <div className="min-h-full px-2 sm:px-2 lg:px-8 flex flex-col gap-y-8 justify-between">
                        <div className="w-[98%] min-h-full md:w-[96%] lg:w-full lg:p-6 pt-16 md:pt-10 pb-28">
                            <Suspense fallback={
                                <div className="flex flex-row justify-center">
                                    <Loader />
                                </div>
                            }>
                                <Outlet />
                            </Suspense>
                        </div>
                        <div className="flex flex-row justify-center">
                            <img
                                className="w-[258px] h-[58px] block dark:hidden"
                                src={`https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Logo-light-mode.png`}
                            />
                            <img
                                className="w-[258px] h-[58px] hidden dark:block"
                                src={`https://${imagesBucket}.s3.us-west-1.amazonaws.com/dashboard-images/Wallmates-Logo-dark-mode.png`}
                            />
                        </div>
                    </div>
                </main>
            </div>
            <DashboardDialog
                open={showLanguageDialog}
                size='md'
                onClose={() => setShowLanguageDialog(false)}
            >
                <DialogTitle>Language Selection</DialogTitle>
                <DialogBody>
                    <Field>
                        <Label>Language</Label>
                        <Select defaultValue={selectedLanguage} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setSelectedLanguage(ev.target.value)}>
                            {
                                languages.map((language: ITranslationLanguage) => {
                                    return <option key={ language.id } value={ language.language_key }>
                                        { language.name }
                                    </option>
                                })
                            }
                        </Select>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button color="zinc" onClick={() => setShowLanguageDialog(false)}>Close</Button>
                    <Button color="emerald" onClick={() => handleLanguageSelect()}>Select</Button>
                </DialogActions>
            </DashboardDialog>
            <Slideover
                open={notificationSlideoverOpen}
                setOpen={setNotificationSlideoverOpen}
                slideoverTitle="Notifications"
            >
                <NotificationsWrapper isOpen={notificationSlideoverOpen} />
            </Slideover>
        </div>
    )
}

export default Dashboard;
