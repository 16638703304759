import { PaymentMethod } from "@stripe/stripe-js";
import { atom } from "jotai";



export const paymentMethodsAtom = atom<PaymentMethod[]>([]);
export const brandBillingAtom = atom<IBrandBilling | null>(null);
export const brandBillingLoadingAtom = atom<boolean>(false);

export interface IBrandBilling {
    id?: number;
    created_at: string;
    brand_id: number;
    stripe_id: string;
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
    email: string;
    first: string;
    last: string;
    phone: string;
    should_bill: boolean;
    currency_method: string;
    current_balance: number;
    wallmates_tokens: number;
    token_plan: TokenPlan;
    autoload_tokens: boolean;
    has_valid_payment_method: boolean;
}

export enum TokenPlan {
    NONE = 'none',
    BASIC = 'token_basic_plan',
    ADVANCED = 'token_advanced_plan',
    PREMIUM = 'token_premium_plan'
}

export interface BrandBillingUpdateBody {
    id: number;
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
    phone?: string;
    currency_method?: string;
    top_up_amount?: string;
    token_plan?: TokenPlan;
    autoload_tokens?: boolean;
}

export interface InsertPaymentMethodRequestBody {
    stripe_customer_id: string;
    type: 'CARD' | 'BANK';
    useAsDefault: boolean;

    account_holder_name?: string;
    card_token?: string;
    account_number?: string;
    currency?: string;
    routing_number?: string;
}